<template>
      <div class="col d-flex align-items-start">
        <div class="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
          <svg class="bi bi-bootstrap-fill" width="1em" height="1em" fill="currentColor">
            <use xlink:href="~bootstrap-icons/bootstrap-icons.svg#pencil"></use>
          </svg>
        </div>
        <div>
          <h3 class="fs-2 text-body-emphasis">{{ titlesnippet }}</h3>
          <p>{{ bodysnippet }}</p>

          <router-link :to="{name:'post', params:{id: post.id}}">
            <a class="btn btn-primary">More...</a>
          </router-link>

        </div>
      </div>
</template>

<script>
import { computed } from 'vue'
export default {
    props:['post'],
    setup(props) {
        const titlesnippet = computed(()=>{
            return props.post.title.substring(0,25)+'...'
        });
        const bodysnippet = computed(()=>{
            return props.post.body.substring(0,50)+'...'
        })
        return { titlesnippet,bodysnippet}
    }
}
</script>

<style>

</style>