import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BoredView from '../views/BoredView.vue'
import PoliceView from '@/views/PoliceView.vue'
import PostsView from '@/views/PostsView.vue'
import PostView from '@/views/PostView.vue'
import PostsTableView from '@/views/PostsTableView.vue'
import StopSearchView from '@/views/StopSearchView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path:'/bored',
    name:'bored',
    component: BoredView
  },
  {
    path:'/police',
    name:'police',
    component: PoliceView
  },
  {
    path:'/stopsearch',
    name:'stopandsearch',
    component: StopSearchView
  },
  {
    path:'/posts',
    name:'posts',
    component: PostsView
  },
  {
    path: '/post/:id',
    name: 'post',
    component: PostView,
    props: true
  },
  {
    path:'/poststable',
    name:'poststable',
    component: PostsTableView
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    component: HomeView 
  },
  {
    path: '/ph',
    name: 'policehome2.0',
    component: () => import(/* webpackChunkName: "about" */ '@/views/vfy/PoliceHomeView.vue')
  },
  {
    path: '/forces2',
    name: 'forces2.0',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/vfy/ForceView.vue')
  },
  {
    path: '/police2',
    name: 'police2.0',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/vfy/PoliceView.vue')
  },
  {
    path: '/availability',
    name: 'availability2.0',
    component: () => import(/* webpackChunkName: "about" */ '@/views/vfy/AvailabilityView.vue')
  },
  {
    path: '/forces',
    name: 'forces',
    component: () => import(/* webpackChunkName: "about" */ '@/views/vfy/ForcesView.vue')
  },
  {
    path: '/stopandsearch2',
    name: 'stopandsearch2.0',
    component: () => import(/* webpackChunkName: "about" */ '@/views/vfy/SSView.vue')
  },
  {
    path: '/multi',
    name: 'multi',
    component: () => import(/* webpackChunkName: "about" */ '@/components/vfy/police/MultiTable.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import( '@/views/news/NewsView.vue' )
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
