<template>
    <h2>{{ singleforce.name }}</h2>
    <p v-html="singleforce.description"></p>
    <p>Tel: {{ singleforce.telephone }}</p>
    <p>URL: {{ singleforce.url }}</p>
    <a :href=singleforce.url class="btn btn-outline-secondary" type="button">Website</a>
</template>

<script>
import getSinglePoliceForce from '@/composables/getSingleForce';

export default {
    name: 'SingleForce',
    props: ['optionreceived'],
    setup(props) {

        const { singleforce, error, load } = getSinglePoliceForce( props.optionreceived )
        load( )

        return { singleforce, error, load }
    }
}
</script>

<style>

</style>