<template>
<div>
  <NavBar />

  <main>
  <div class="container py-4">
    <header class="pb-3 mb-4 border-bottom">
      <a href="/" class="d-flex align-items-center text-body-emphasis text-decoration-none">
        
        <svg class="bi" width="40" height="40" fill="currentColor" style="color: charcoal;">
            <use xlink:href="~bootstrap-icons/bootstrap-icons.svg#incognito"></use>
        </svg>

        <span class="fs-4 ms-2">HM Police</span>
      </a>
    </header>

    <div class="p-5 mb-4 bg-body-tertiary rounded-3">
      <div class="container-fluid py-5">
        <h1 class="display-5 fw-bold">HM Police</h1>
        <p class="col-md-8 fs-4">HM Police API is used to view some basic information of Police Forces across the United Kingdom. There is also some Stop and Search Data which can be viewed and filtered depending on what the API offers.</p>
        <!-- <button class="btn btn-primary btn-lg" type="button">Example button</button> -->
        <router-link :to="{ name: 'stopandsearch' }" class="btn btn-primary btn-lg">Stop and Search</router-link>
      </div>
    </div>

    <div class="row align-items-md-stretch">
      <div class="col-md-6">
        <div class="h-100 p-5 text-bg-dark rounded-3">
          <h2>Police Forces</h2>
          <div v-if="forces.length>1">
            <PoliceForces :forcelist="forces" 
              @optionEmitted="handleEmittedOption" />
          </div> 
        </div>
      </div>
      
      <div class="col-md-6">
        <div class="h-100 p-5 bg-body-tertiary border rounded-3" v-if="optionselected">
          <div v-if="optionselected">
            <SingleForce :optionreceived="optionselected" :key="optionselected"/>
          </div>
        </div>
      </div>
    </div>

    <div class="row align-items-md-stretch py-4 mh-50" v-if="optionselected">
        <SeniorOfficers :forceid="optionselected" :key="optionselected" />
    </div>

    <footer class="pt-3 mt-4 text-body-secondary border-top">
      © 2024
    </footer>
  </div>
</main>

  </div>
</template>

<script>
import { inject, ref } from 'vue';
import NavBar from '@/components/NavBar.vue'
import getPoliceForces from '@/composables/getPolice';
import PoliceForces from '@/components/PoliceForces.vue';
import SingleForce from '@/components/SingleForce.vue';
import SeniorOfficers from '@/components/SeniorOfficers.vue';

export default {
    name: 'PoliceView',
    components: { NavBar, PoliceForces, SingleForce, SeniorOfficers },
    setup() {

      const optionselected = ref()
      const {forces, error, load} = getPoliceForces( )
      load()

      const handleEmittedOption = ( emittedOption ) => {
        // console.log('Catch emitted')
        // console.log(emittedOption)
        optionselected.value = emittedOption
        // console.log(optionselected)
      }

      return { forces, error, load, handleEmittedOption, 
        optionselected }
    }
}
</script>

<style>
#app > div > main > div > div.p-5.mb-4.bg-body-tertiary.rounded-3 > div
{
  text-align: justify;
}
#app > div > main > div > div.row.align-items-md-stretch > div:nth-child(1) > div > div > div
{
  margin-top: 30px;
}
</style>