<template>
<div v-if="error.length>10"><p class="my-5 fs-4">{{ error }}</p></div>

<div v-else>

  <div class="row">
    <div class="col-sm">
      <label for="selfethnicity" class="form-label fs-4"></label>
      <select name="selfethnicity" id="selfethnicity" class="form-select"
              v-model="selfethnicity">
        <option value="">Choose Ethnicity...</option>
        <option v-for="ethnicity in ethnicities" 
                  :key="ethnicity" 
                  :value="ethnicity">{{ ethnicity }}</option>
      </select>
    </div>

    <!-- <div v-show="showagerange===true" class="col"> -->
    <div class="col-sm">
      <label for="selectagerange" class="form-label fs-4"></label>
      <select name="selectagerange" id="selectagerange" class="form-select"
              v-model="agefilter">
        <option value="" selected>Choose Age Range...</option>
        <option v-for="ages in agerange" :key="ages" :value="ages">{{ ages }}</option>
      </select>
    </div>
    <!-- </div> -->

  </div>

  <div class="row text-start mt-3">

    <div class="col-4 col-sm-3 form-check">
      <input class="form-check-input" type="checkbox" value="" v-model="chtype" id="chType">
      <label class="form-check-label" for="chType">
        Type
      </label>
    </div>
    <div class="col-4 col-sm-3 form-check">
      <input class="form-check-input" type="checkbox" value="" v-model="chperson" id="chPerson">
      <label class="form-check-label" for="chPerson">
        Person
      </label>
    </div>
    <div class="col-4 col-sm-3 form-check">
      <input class="form-check-input" type="checkbox" value="" v-model="chdate" id="chDate">
      <label class="form-check-label" for="chDate">
        Date/time
      </label>
    </div>
    <div class="col-4 col-sm-3 form-check">
      <input class="form-check-input" type="checkbox" value="" v-model="chop" id="chOp">
      <label class="form-check-label" for="chOp">
        Op. Info
      </label>
    </div>
    <div class="col-4  col-sm-3 form-check">
      <input class="form-check-input" type="checkbox" value="" v-model="chgender" id="chGender">
      <label class="form-check-label" for="chGender">
        Gender
      </label>
    </div>
    <div class="col-4 col-sm-3 form-check">
      <input class="form-check-input" type="checkbox" value="" v-model="chagerange" id="chAgerange">
      <label class="form-check-label" for="chAgerange">
        Age Range
      </label>
    </div>
    <div class="col-6 col-sm-3 form-check">
      <input class="form-check-input" type="checkbox" value="" v-model="chselfethnicity" id="chSelfEthnicity">
      <label class="form-check-label" for="chSelfEthnicity">
        Self-Ethnicity
      </label>
    </div>
    <div class="col-6 col-sm-3 form-check">
      <input class="form-check-input" type="checkbox" value="" v-model="choffethnicity" id="chOffEthnicity">
      <label class="form-check-label" for="chOffEthnicity">
        Off.Ethnicity
      </label>
    </div>
    <div class="col-6 col-sm-3 form-check">
      <input class="form-check-input" type="checkbox" value="" v-model="chlegislation" id="chLegislation">
      <label class="form-check-label" for="chLegislation">
        Legislation
      </label>
    </div>
    <div class="col-6 col-sm-3 form-check">
      <input class="form-check-input" type="checkbox" value="" v-model="chobjsearch" id="chObjSearch">
      <label class="form-check-label" for="chObjSearch">
        Object Search
      </label>
    </div>
    <div class="col-6 col-sm-3 form-check">
      <input class="form-check-input" type="checkbox" value="" v-model="choutcome" id="chOutcome">
      <label class="form-check-label" for="chOutcome">
        Outcome
      </label>
    </div>
    <!-- <div class="col form-check">
      <input class="form-check-input" type="checkbox" value="" v-model="chobjoutcome" id="chObjOutcome">
      <label class="form-check-label" for="chObjOutcome">
        Outcome Object
      </label>
    </div> -->
    <div class="col-12 col-sm-3 form-check">
      <input class="form-check-input" type="checkbox" value="" v-model="chobjoutcomelink" id="chObjOutcomeLink">
      <label class="form-check-label" for="chObjOutcomeLink">
        Outcome linked to object of search
      </label>
    </div>
    <div class="col-12 col-sm-3 form-check">
      <input class="form-check-input" type="checkbox" value="" v-model="chclothing" id="chClothing">
      <label class="form-check-label" for="chClothing">
        Removal of Outer Clothing
      </label>
    </div>
  </div>

  <div class="d-none d-sm-block">
    <div class="row justify-content-between">
      <div class="col-1">
        <button class="btn btn-primary btn-lg my-3" type="button" @click="searchs">Search</button>
      </div>
      <div class="col-sm-4 text-right align-self-center" v-if="totalresults">
          Returned {{ totalresults }} Results</div>
    </div>
  </div>

  <div class="d-block d-sm-none">
    <div class="row justify-content-between">
      <div class="col-1">
        <button class="btn btn-primary btn-sm my-3" type="button" @click="searchs">Search</button>
      </div>
      <div class="col-8 text-right align-self-center p-0" v-if="totalresults">
        Returned {{ totalresults }} Results
      </div>
    </div>
  </div>


  <table class="table table-light d-block d-sm-none">
    <tbody v-if="searchresults && searchresults.length>0" class="text-start">
      <tr v-for="rec in searchresults" :key="rec" class="table-group-divider">

        <tr v-show="chtype"><th scope="row" class="col" colspan="2">Search Type:</th></tr>
        <tr v-show="chtype"><td class="col" colspan="2">{{ rec.type }}</td></tr>

        <tr><th scope="row" class="col-3">Person:</th><td v-show="chperson" class="col-6">{{ rec.involved_person }}</td></tr>

        <tr v-show="chdate" class="col-6"><th scope="row">Date/Time</th></tr>
        <tr v-show="chdate" class="col-6"><td colspan="2">{{ rec.datetime }}</td></tr>
        
        <tr v-show="chop"><th scope="row">Operation</th><td>{{ rec.operation }}</td></tr>
        <tr v-show="chop"><th scope="row" class="col-6">Operation Name</th><td>{{ rec.operation_name }}</td></tr>
        
        <tr v-show="chgender"><th scope="row">Gender</th><td>{{ rec.gender }}</td></tr>
        <tr v-show="chagerange"><th scope="row">Age Range</th><td>{{ rec.age_range }}</td></tr>

        <tr v-show="chselfethnicity"><th scope="row" colspan="2">Self Defined Ethnicity</th></tr>
        <tr v-show="chselfethnicity"><td colspan="2">{{ rec.self_defined_ethnicity }}</td></tr>
        <tr v-show="choffethnicity"><th colspan="2" scope="row">Officer Defined Ethnicity</th></tr>
        <tr v-show="choffethnicity"><td colspan="2">{{ rec.officer_defined_ethnicity }}</td></tr>

        <tr v-show="chlegislation"><th colspan="2" scope="row">Legislation</th></tr>
        <tr v-show="chlegislation"><td colspan="2">{{ rec.legislation }}</td></tr>

        <tr v-show="chobjsearch"><th colspan="2" scope="row">Object of Search</th></tr>
        <tr v-show="chobjsearch"><td colspan="2">{{ rec.object_of_search }}</td></tr>

        <tr v-show="choutcome"><th scope="row">Outcome</th></tr>
        <tr v-show="choutcome"><td colspan="2">{{ rec.outcome }}</td></tr>

        <tr v-show="choutcome"><th scope="row" colspan="2">Outcome linked to search</th></tr>
        <tr v-show="chobjoutcomelink"><td colspan="2">{{ !rec.outcome_linked_to_object_of_search ? 'N/A' : rec.outcome_linked_to_object_of_search }}</td></tr>
        <tr v-show="chclothing"><th scope="row" colspan="2">Outer Clothing Removal</th></tr>
        <tr v-show="chclothing"><td>{{ rec.removal_of_more_than_outer_clothing }}</td></tr>
      </tr>
    </tbody>
  </table>


    <table class="table table-striped d-none d-sm-block">
    <thead>
      <tr>
        <th scope="col" v-show="chtype">Type</th>
        <th scope="col" v-show="chperson">Person</th>
        <th scope="col" v-show="chdate">DateTime</th>
        <th scope="col" v-show="chop">Operation</th>
        <th scope="col" v-show="chop">Op. Name</th>
        <th scope="col" v-show="chgender">Gender</th>
        <th scope="col" v-show="chagerange">Age</th>
        <th scope="col" v-show="chselfethnicity">Self-Ethnicity</th>
        <th scope="col" v-show="choffethnicity">Officer Defined Ethnicity</th>
        <th scope="col" v-show="chlegislation">Legislation</th>
        <th scope="col" v-show="chobjsearch">Object of Search</th>
        <th scope="col" v-show="choutcome">Outcome</th>
        <!-- <th scope="col" v-show="chobjoutcome">Outcome Object</th> -->
        <th scope="col" v-show="chobjoutcomelink">Outcome-Object Link</th>
        <th scope="col" v-show="chclothing">Clothing Removal</th>
      </tr>
    </thead>

    <tbody v-if="searchresults && searchresults.length>0">
    <tr v-for="rec in searchresults" :key="rec">
        <th v-show="chtype" scope="row">{{ rec.type }}</th>
        <td v-show="chperson">{{ rec.involved_person }}</td>
        <td v-show="chdate">{{ rec.datetime }}</td>
        <td v-show="chop">{{ rec.operation }}</td>
        <td v-show="chop">{{ rec.operation_name }}</td>
        <td v-show="chgender">{{ rec.gender }}</td>
        <td v-show="chagerange">{{ rec.age_range }}</td>
        <td v-show="chselfethnicity">{{ rec.self_defined_ethnicity }}</td>
        <td v-show="choffethnicity">{{ rec.officer_defined_ethnicity }}</td>
        <td v-show="chlegislation">{{ rec.legislation }}</td>
        <td v-show="chobjsearch">{{ rec.object_of_search }}</td>
        <td v-show="choutcome">{{ rec.outcome }}</td>
        <!-- <td v-show="chobjoutcome">{{ rec.outcome_object }}</td> -->
        <td v-show="chobjoutcomelink">{{ rec.outcome_linked_to_object_of_search }}</td>
        <td v-show="chclothing">{{ rec.removal_of_more_than_outer_clothing }}</td>
      </tr>
    </tbody>

    <tbody v-else>
    <tr v-for="rec in stopsearch" :key="rec">
        <th v-show="chtype" scope="row">{{ rec.type }}</th>
        <td v-show="chperson">{{ rec.involved_person }}</td>
        <td v-show="chdate">{{ rec.datetime }}</td>
        <td v-show="chop">{{ rec.operation }}</td>
        <td v-show="chop">{{ rec.operation_name }}</td>
        <td v-show="chgender">{{ rec.gender }}</td>
        <td v-show="chagerange">{{ rec.age_range }}</td>
        <td v-show="chselfethnicity">{{ rec.self_defined_ethnicity }}</td>
        <td v-show="choffethnicity">{{ rec.officer_defined_ethnicity }}</td>
        <td v-show="chlegislation">{{ rec.legislation }}</td>
        <td v-show="chobjsearch">{{ rec.object_of_search }}</td>
        <td v-show="choutcome">{{ rec.outcome }}</td>
        <!-- <td v-show="chobjoutcome">{{ rec.outcome_object }}</td> -->
        <td v-show="chobjoutcomelink">{{ rec.outcome_linked_to_object_of_search }}</td>
        <td v-show="chclothing">{{ rec.removal_of_more_than_outer_clothing }}</td>
      </tr>
    </tbody>


  </table>

</div>

</template>

<script>

import getStopSearchForce from '@/composables/getStopSearchForce';
import { onUpdated, ref } from 'vue';
import {_} from 'vue-underscore'

export default {
    name: 'StopSearchForce',
    props:['optionreceived'],
    setup(props) {
      // console.log(props)
      const { stopsearch, error, load } = 
              getStopSearchForce( props.optionreceived.location, props.optionreceived.date )
      load( ).then(
        () => {
          loadDDs()
          searchs()
          // console.log(error.value, error.value.length)
        }
      )

      const ethnicities = ref([])
      const agerange = ref([])
      const showagerange=ref(false)
      const searchresults = ref([])
      const agefilter = ref()
      const selfethnicity = ref()
      const totalresults = ref()

      const chtype = ref(true) 
      const chperson = ref(true)
      const chdate = ref()
      const chop = ref()
      const chgender = ref(true)
      const chagerange = ref(true)
      const chselfethnicity = ref()
      const choffethnicity = ref()
      const chlegislation = ref(true)
      const chobjsearch = ref()
      const choutcome = ref()
      const chobjoutcome = ref()
      const chobjoutcomelink = ref()
      const chclothing = ref()
      

      const loadDDs = () => {
        stopsearch.value.forEach((item, index) => { 
          // console.log(item, index);
          agerange.value.push(item.age_range);
          ethnicities.value.push( item.self_defined_ethnicity );
        })
        // console.log(_.uniq(agerange.value,false))
        agerange.value = _.uniq( agerange.value, false )
        // console.log( agerange.value )
        // console.log( agerange.value.indexOf(null)  )
        agerange.value = agerange.value.toSpliced( agerange.value.indexOf(null), 1 )
        // console.log( agerange.value )
        showagerange.value = true

        ethnicities.value = _.uniq( ethnicities.value, false )
        ethnicities.value = ethnicities.value.toSpliced( ethnicities.value.indexOf(null),1 )
        selfethnicity.value=''
        agefilter.value=''
      }

      const searchs = () => {
        // console.log( chtype.value, chperson.value )
        searchresults.value = []
        let search = {}
        // console.log( agefilter, selfethnicity )
        // console.log(typeof(selfethnicity.value))
        // console.log(typeof(agefilter.value))

        if( typeof(agefilter.value) !=='undefined' ) {
          // console.log('agefilter > ',agefilter.value, ' L >', agefilter.value.length )
          agefilter.value.length>0 ? search.agerange=agefilter.value : ''
        }
        if( typeof(selfethnicity.value) !=='undefined' ) {
          // console.log('self eth > ', selfethnicity.value, ' L ', selfethnicity.value.length )
          selfethnicity.value.length>0 ? search.selfethnicity=selfethnicity.value : ''
        }
        // console.log(search)

        stopsearch.value.forEach( (item, index) => {
          // console.log(typeof(search.agerange), typeof(item.age_range))
          let ages = ''
          if( search.agerange ){
            ages = 'search.agerange===item.age_range'
          } else {
            ages = '1===1'
          }

          let selfe = ''
          if( search.selfethnicity ) {
            selfe = 'search.selfethnicity===item.self_defined_ethnicity'
          } else {
            selfe = '1===1'
          }

          if( 1===1   
            && ( eval( ages )) 
            && ( eval(selfe) )
          ) {
            // console.log(typeof(search.agerange), typeof(item.age_range), search.agerange, item.age_range)           
            searchresults.value.push(item)
          }
        
          // item.age_range
          // item.self_defined_ethnicity
          // item.datetime
          // item.gender
          // item.operation
          // item.operation_name
          // item.type
          // item.involved_person
          // item.officer_defined_ethnicity
          // item.legislation       
          // item.object_of_search
          // item.outcome
          // item.outcome_linked_to_object_of_search
          // item.outcome_object
          // item.removal_of_more_than_outer_clothing

          // item.location
        })
        // console.log(searchresults.value)
        // console.log(searchresults.value.length)
        totalresults.value = searchresults.value.length
      }

      onUpdated(()=>{
        // console.log('updated')
        // console.log(error.value)
      })

      return { stopsearch, error, load, ethnicities, 
          loadDDs, agerange, showagerange, 
          agefilter, selfethnicity, searchs,
          searchresults,totalresults,
          chtype, chperson, chdate, chop, chgender, chagerange, chselfethnicity, 
          choffethnicity, chlegislation, chobjsearch,choutcome, chobjoutcome,
          chobjoutcomelink, chclothing
         }
    }
}
</script>

<style>

</style>