<template>

<NavBar />
  <div class="container-fluid">
    <main>

    <!-- First Container -->
    <div class="container px-4 py-4" id="hanging-icons">
        <h2 class="pb-2 border-bottom">Posts</h2>
        <h5 class="pt-2">JSON Placeholder</h5>

            <div v-if="posts.length" class="row g-4 py-4 row-cols-1 row-cols-lg-3">
                <PostList :posts="posts" />
            </div>

    </div>

    </main>
  </div>
</template>

<script>

import NavBar from '@/components/NavBar.vue'
import getPosts from '@/composables/getPosts'
import PostList from '@/components/PostList.vue'
import { ref } from 'vue'

export default {
    name: 'PostsView',
    components: { NavBar, PostList },
    setup() {
        const { posts, error, load } = getPosts( )
        load()
        return { posts, error }
    }
}
</script>

<style>

</style>