import { ref } from "vue"
import {_} from 'vue-underscore'

const getStopSearchForce = ( forceid, crimedate ) => {
    const stopsearchurl = 'https://data.police.uk/api/stops-force?force=' + forceid
                                + '&date=' + crimedate
    const stopsearch = ref([])
    const error = ref([null])
    
    const load = async() => {
        try {
            let stopsearchdata = await fetch( stopsearchurl )
            if( !stopsearchdata.ok ) {
                throw Error('No force data found. Try another force.')
            }
            stopsearch.value = await stopsearchdata.json()
        } catch (err) {
            error.value = err.message
            // console.log(err)
        }
    }
    return { stopsearch, error, load }
}
export default getStopSearchForce

export async function useStopSearchForce( forceid, crimedate ) {
    const stopsearchurl = 'https://data.police.uk/api/stops-force?force=' + forceid + '&date=' + crimedate
    const stopsearch = ref([])
    const error = ref([null])
    const filters = ref([])
    try {
        let stopsearchdata = await fetch( stopsearchurl )
        if( !stopsearchdata.ok ) {
            throw Error('No force data found. Try another force.')
        }
        stopsearch.value = await stopsearchdata.json()
        filters.value = loadFilters(stopsearch)
        // if (stopsearch.value) {
        //     filters.value = loadFilters(stopsearch)
        // }
        
        // if ( stopsearch.value !== null ) {
        //      filters.value =  loadFilters( stopsearch )
        // console.log( 'fff>>>', stopsearch.value, filters.value )
        // }
       
        
    } catch (err) {
        error.value = err.message
    }
    return { stopsearch, error, filters }
}

 function loadFilters( tabledata ) {
    // console.log('loadfilters');
    // console.log(tabledata)
    // tabledata.forEach(
    //     element => {
    //         console.log(element);
    //     }
    // )
  let filters = []
  let eths = []
  let genders=[] 
  let legislation=[]
  let age=[]
  let objectofsearch=[]
  let officerdefinedethnicity=[]
  let operation=[]
  let outcome=[]
  let types=[]
  
  tabledata.value.forEach(element => {
    eths.push(element.self_defined_ethnicity)
    genders.push(element.gender)
    legislation.push(element.legislation)
    age.push( element.age_range )
    objectofsearch.push( element.object_of_search )
    officerdefinedethnicity.push( element.officer_defined_ethnicity )
    element.operation !== null ? operation.push( element.operation.toString() ) : null
    outcome.push( element.outcome )
    types.push( element.type )
  });
 
  filters['selfethnicities'] = _.uniq( eths, false )
  filters['gender'] = _.uniq( genders, false )
  filters['legislation'] = _.uniq( legislation, false )
  filters['agerange'] = _.uniq( age, false )
  filters['objectofsearch'] = _.uniq( objectofsearch, false )
  filters['officerdefinedethnicity'] = _.uniq( officerdefinedethnicity, false )
  filters['operation'] = _.uniq( operation, false )
  filters['outcome'] = _.uniq( outcome, false )
  filters['types'] = _.uniq( types, false )
//   console.log('loadfilters', filters);
  return filters
}