<template>
<NavBar />

  <div id="flashMessage" v-if="GStore.flashMessage">
    {{ GStore.flashMessage }}
  </div>

<main>
<div class="container py-4">
    <header class="pb-3 mb-4 border-bottom">
        <a href="/" class="d-flex align-items-center text-body-emphasis text-decoration-none">
          <svg class="bi" width="40" height="40" fill="currentColor" style="color: charcoal;">
            <use xlink:href="~bootstrap-icons/bootstrap-icons.svg#incognito"></use>
        </svg>
        <span class="fs-4 ms-2 text-start d-none d-sm-block">HM Police - Stop and Search - By Force</span>
        <span class="fs-4 ms-2 text-start d-block d-sm-none">HM Police<br><span class="fs-6">Stop and Search - By Force</span></span>
        </a>
    </header>

    <div class="h-100 p-5 bg-body-tertiary border rounded-3">
        <StopSearchAvailability @availocOptionEmitted="handleAvailLoc" />



        <div class="" 
        v-if="stopsearchlocselected && stopsearchlocselected !=='Choose Force...' ">
        <StopSearchForce :optionreceived="{location:stopsearchlocselected,date:stopsearchdateselected}" 
                        :key="stopsearchlocselected" />
        </div>


      



        
    </div>

</div>
</main>
</template>

<script>
import { ref, inject } from 'vue';
import NavBar from '@/components/NavBar.vue'
import getPoliceForces from '@/composables/getPolice';
// import PoliceForces from '@/components/PoliceForces.vue';
import StopSearchForce from '@/components/StopSearchForce.vue'
import StopSearchAvailability from '@/components/StopSearchAvailability.vue';

export default {
    name: 'StopSearchView',
    components: { NavBar, /*PoliceForces,*/ StopSearchForce, StopSearchAvailability },
    setup() {
      const GStore = inject( 'GStore' )
      const optionselected = ref()
      const stopsearchdateselected = ref()
      const stopsearchlocselected = ref()
      const {forces, error, load} = getPoliceForces( )
      load()

      const handleEmittedOption = ( emittedOption ) => {
        optionselected.value = emittedOption
      }

      const locationemitted=ref(false)
      const handleAvailLoc = ( emittedOption ) => {
        // console.log( emittedOption )
        emittedOption ? locationemitted.value = true : false
        stopsearchlocselected.value = emittedOption.location
        stopsearchdateselected.value = emittedOption.date
      }

      return { forces, error, load, 
            handleEmittedOption, optionselected, 
            handleAvailLoc, stopsearchlocselected, stopsearchdateselected, locationemitted, GStore }
    }
}
</script>

<style>
@keyframes yellowfade {
  from {
    background: #b44bab;
  }
  to {
    background: transparent;
  }
}

#flashMessage {
  animation-name: yellowfade;
  animation-duration: 3s;
}
</style>