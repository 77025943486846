<template>

<NavBar />

<div class="container-fluid">

<!-- <aside class="col-4"><Sidebar /></aside> -->


<main>
  <div class="container">

  <div class="my-5">
    <div class="p-5 text-center bg-body-tertiary">
      <div class="container py-5">
        
        <svg class="bi" width="100" height="100" fill="currentColor" style="color: charcoal;">
            <use xlink:href="../../node_modules/bootstrap-icons/bootstrap-icons.svg#signpost-split"></use>
        </svg>

        <h1 class="text-body-emphasis">Bored API</h1>
        <p class="col-lg-8 mx-auto lead">
          This is a demo of the Bored API. It facilitates your choice of an activity depending on your input of <code>Participants</code> and <code>Activity</code>. It has been put together using <code>Vue and Fetch API</code>.
        </p>
      </div>
    </div>
  </div>

    <!-- First Container -->
    <!-- <div class="text-center bg-info-subtle my-3 py-4 rounded">
      <h1>Bored API</h1>
      <h6>Bored API Demo</h6>
    </div> -->

    <div class="text-center bg-secondary-subtle rounded container">

      <div class="input-group px-2 py-3">
        <select class="form-select" id="activityparticipants" aria-label="Activity Participants" 
                v-on:change="ddactivityparticipants($event)">
          <option value="" selected>Choose Participants...</option>
          <option v-for="party in participants" :value=party :key=party>
            {{ party }}
          </option>
        </select>
      </div>

      <div class="input-group px-2 pb-3">
        <select class="form-select" id="activitytype" aria-label="Activity Type"
                 v-on:change="ddactivity($event)">
          <option value="">Choose Activity Type...</option>
          <option v-for="opt in opts" :value=opt :key=opt>
            {{ opt }}
          </option>
          
        </select>
      </div>

    </div>  

    <div class="text-center mt-3 p-sm-5 rounded">
      <Bored :key={activitytype,activityparticipants} :party=activityparticipants :type=activitytype></Bored>
    </div>

  </div>
</main>
</div>
</template>

<script>
import Bored from '@/components/Bored.vue'
import NavBar from '@/components/NavBar.vue'

import { ref } from 'vue'
export default {
  name: 'BoredView',
  components: { Bored, NavBar },
  setup() {
    const activitytype = ref()
    const ddactivity = (event) => {
      activitytype.value = event.target.value
    }
    const opts = ["education", "recreational", "social", "diy", "charity", "cooking", "relaxation", "music", "busywork"]

    const activityparticipants=ref()
    const ddactivityparticipants = (event) => {
      activityparticipants.value = event.target.value
    }
    const participants = ref([1,2,3,4,5,6,7,8,9])

    return { activitytype, ddactivity, opts, 
              participants, activityparticipants, ddactivityparticipants 
            }
  }
}
</script>

<style>

</style>