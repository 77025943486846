import { ref } from "vue";

const getPoliceForces = () => {
    const forcesurl = 'https://data.police.uk/api/forces'
    const forces = ref([null])
    const error = ref([null])

    const load = async() => {
        try {
            // console.log(forcesurl)
            let forcesdata = await fetch( forcesurl )
            if( !forcesdata.ok ) {
                throw Error('Computer says no!')
            }
            forces.value = await forcesdata.json()
        } catch (err) {
            error.value = err.message
            // console.log( err.value )
        }
    }
    return { forces, error, load }
}
export default getPoliceForces

export async function usePoliceForces() {
    const forcesurl = 'https://data.police.uk/api/forces'
    const forces = ref([null])
    const error = ref([null])

    try {
        // console.log(forcesurl)
        let forcesdata = await fetch( forcesurl )
        if( !forcesdata.ok ) {
            throw Error('Computer says no!')
        }
        forces.value = await forcesdata.json()
    } catch (err) {
        error.value = err.message
        // console.log( err.value )
    }
    return { forces, error }
}