<template>
<div class="row">
    <h2>Select Dataset</h2>
    <div class="col-sm">
    <label class="form-label" for="ssavail"></label>
    <select name="ssavail" id="ssavail" class="form-select" @change="handleAvailChange">
        <option>Choose Year...</option>
        <option v-for="ss in stopandsearchavailability" :key="ss.date" :value="ss.date">
        {{ ss.date }}
        </option>
    </select>
</div>

<div class="col-sm">
    <!-- <div v-if="optionselected"> -->
        <label class="form-label" for="sslocation"></label>
        <select name="sslocation" id="sslocation" class="form-select"
                @change="handleAvailLocationChange">
            <option>Choose Region...</option>
            <option v-for="selectedforce in selectedforces" 
                    :key="selectedforce"
                    :value="selectedforce">
                {{selectedforce}}
            </option>
        </select>
    <!-- </div> -->
</div>

</div>    
</template>

<script>
import getStopAndSearchAvailiability from '@/composables/getSSavailability';
import { inject, ref } from 'vue';
export default {
    name: 'StopSearchAvailability',
    emits:['availocOptionEmitted'],
    setup( props, {emit} ) {
        const optionselected = ref()
        const selectedforces = ref()
        const handleAvailChange = (id) => {
            // console.log(id)
            // emit( 'availOptionEmitted', id.target.value )
            // console.log(id.target.value)
            optionselected.value = id.target.value
            // console.log(id.target.selectedOptions[0].value)
            let rw = stopandsearchavailability.value.find(
                ({date})=>date===optionselected.value
            )
            selectedforces.value = rw['stop-and-search']
            // console.log(rw['stop-and-search'])
            // console.log(selectedforces)
            // console.log( stopandsearchavailability.value.find((value,index)=>{
            //     // console.log(value.date)
            //     console.log(id.target.value.date)
            //     if( value.date === id.target.value  ) {
            //         console.log(value)
            //     }
                
            // }) )
            // selectedforces.value = stopandsearchavailability.value.date[id.target.value]
        }

        const GStore = inject( 'GStore' )
        const handleAvailLocationChange = (id) => {
            // console.log(optionselected.value, id.target.value)
            emit( 'availocOptionEmitted', 
                { date:optionselected.value, location: id.target.value} 
            )
            GStore.flashMessage = 'You have selected ' + id.target.value
            setTimeout(() => {
                GStore.flashMessage=''
            }, 3000);
        }

        const { stopandsearchavailability, error, load } = getStopAndSearchAvailiability()
        load()
        return { stopandsearchavailability, error, load, handleAvailChange, 
                optionselected, selectedforces, handleAvailLocationChange }
    }
}
</script>

<style>

</style>