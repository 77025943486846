<template>
  <NavBar />
  <div class="container" v-if="viewingposts && postscount">
    <!-- <h1>V-Paginate-3 - Just Posts</h1> -->
    <h2 class="pb-2 border-bottom pt-4">Posts</h2>
        <h5 class="pt-2">JSON Placeholder</h5>
    
    <pagination v-model="page" :records="postscount" :per-page="limit" @paginate="changepage" :options=opts></pagination>

    <table class="table table-striped">
        <thead>
            <tr>
            <!-- <th scope="col">Serial</th> -->
            <th scope="col">Title</th>
            <th scope="col">Body</th>
            </tr>
        </thead>

        <tbody v-if="viewingposts && viewingposts.length>0">
            <tr v-for="( post, index ) in viewingposts" :key="index">
                <!-- <th scope="row">{{ post.id }}</th> -->
                <td><router-link :to="{name:'post', params:{id: post.id}}">{{post.title}}</router-link></td>
                <td>{{ post.body }}</td>
            </tr>
        </tbody>

        <tbody v-else>
            <tr v-for="( post, index ) in posts" :key="index">
                <!-- <th scope="row">{{ post.id }}</th> -->
                <td>{{post.title}}</td>
                <td>{{ post.body }}</td>
            </tr>
        </tbody>
    </table>
    
    
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import getPostsTable from '@/composables/getPostsTable'
import Pagination from 'v-pagination-3'
import { ref } from 'vue'
export default {
    name: 'PostsTableView',
    components: { NavBar, Pagination },
    setup() {
        // console.log('setup');
        const { posts, error, load, postscount } = getPostsTable( )
        load().then(
           () => {changepage(1)}
        )

        const page = ref(1);
        // const postscount = ref(100);
        const limit = ref(10);
        const viewingposts = ref('');
        const opts = ref({
            // chunksNavigation:'scroll',
            chunk:5
        })
        
        const changepage = async (newpage) => {

            const startingpoint = (newpage * limit.value) - limit.value;
            // console.log('start '+startingpoint);
            const endpoint = startingpoint + limit.value;
            // console.log('end ' + endpoint);
            // viewingposts.value = ''; ok
            viewingposts.value = posts.value.slice( startingpoint, endpoint );
            // console.log(viewingposts.value);
        };

        return { posts, error, 
            page, postscount, limit, 
            changepage, viewingposts, opts
        }
    }  
}
</script>

<style>
    #app > div > h1 {
        margin-top: 20px;   
    }
    .VuePagination > nav > ul {
        justify-content : center;
    }
</style>