<template>
  <!-- <div v-for="post in posts" :key="post.id"> -->
    <SinglePost v-for="post in posts" :key="post.id" :post="post" />
  <!-- </div> -->
</template>

<script>

import SinglePost from '@/components/SinglePost.vue'

export default {
    props:['posts'],
    components: { SinglePost }
}
</script>

<style>

</style>