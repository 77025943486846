<template>
  <nav id="mainmenu" class="navbar navbar-expand-sm navbar-dark bg-dark" aria-label="Third navbar example">
    <div class="container-fluid">
      
      
      <router-link :to="{ name: 'home' }">
        <svg class="bi bi-bootstrap-fill pe-none me-2 text-light text-opacity-75" width="40" height="32" fill="currentColor">
          <use xlink:href="~bootstrap-icons/bootstrap-icons.svg#globe2"></use>
        </svg>
      </router-link>


      <span class="navbar-brand">
        <router-link :to="{ name: 'home' }">API Central</router-link>
      </span>


      <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample03" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-collapse collapse" id="navbarsExample03" style="">
        <ul class="navbar-nav me-auto mb-2 mb-sm-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="/">Home</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Explore APIs</a>
            <ul class="dropdown-menu">
              <li class="dropdown-item"><router-link :to="{ name: 'about' }">About</router-link></li>
              <li class="dropdown-item"><router-link :to="{ name: 'bored' }">Bored</router-link></li>
              <li class="dropdown-item"><router-link :to="{ name: 'police' }">HM Police</router-link></li>
              <li class="dropdown-item"><router-link :to="{ name: 'policehome2.0' }">HM Police (Vuetify)</router-link></li>
              <li class="dropdown-item"><router-link :to="{ name: 'posts' }">Posts (JSON Placeholder)</router-link></li>
              <li class="dropdown-item"><router-link :to="{ name: 'poststable' }">Posts Table</router-link></li>
              <li class="dropdown-item"><router-link :to="{ name: 'news' }">News</router-link></li>
            </ul>
          </li>
        </ul>
        <form role="search">
          <input class="form-control" type="search" placeholder="Search" aria-label="Search">
        </form>
      </div>
    </div>
  </nav>
</template>

<script>
export default {

}
</script>

<style>
#mainmenu>div>span.navbar-brand a {
    color: white;
    text-decoration: none;
}

</style>