<template>
  <!-- <NavBar /> -->

  <div class="vh-100 border d-flex align-items-center justify-content-center">
  <div class="px-4  text-center">
    <svg class="bi bi-bootstrap-fill pe-none me-2 mb-3" width="150" height="150" fill="currentColor">
      <use xlink:href="~bootstrap-icons/bootstrap-icons.svg#globe2"></use>
    </svg>
    <h1 class="display-5 fw-bold text-body-emphasis mb-3">API Central</h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">A Demo of API Consumption using Bootstrap and VueJS.</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center flex-wrap" id="navbuttons">
        <span class="btn btn-outline-secondary btn-lg px-4"><router-link :to="{ name: 'bored' }">Bored</router-link></span>
        <span class="btn btn-outline-secondary btn-lg px-4"><router-link :to="{ name: 'posts' }">JSON Placeholder</router-link></span>
        <span class="btn btn-outline-secondary btn-lg px-4"><router-link :to="{ name: 'police' }">HM Police</router-link></span>
        <span class="btn btn-outline-secondary btn-lg px-4"><router-link :to="{ name: 'policehome2.0' }">HM Police (Vuetify)</router-link></span>
        <span class="btn btn-outline-secondary btn-lg px-4"><router-link :to="{ name: 'news' }">News</router-link></span>
        <span class="btn btn-outline-secondary btn-lg px-4"><router-link :to="{ name: 'police' }">Amazon</router-link></span>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import NavBar from '@/components/NavBar.vue'
export default {
    name: 'HomeView',
    components:{ NavBar }
}
</script>

<style scoped>
#navbuttons > span a {
    color: grey;
    text-decoration: none;
}
</style>